import APIService from './APIService';

export default {
  getRegraExcecao(postData) {
    return APIService.apiCall().post(
      'validacoes-bdgds/regras-excecoes/get-regra-excecao',
      JSON.stringify(postData)
    );
  },
  salvarRegraExcecao(postData) {
    return APIService.apiCall().post(
      'validacoes-bdgds/regras-excecoes/salvar-regra-excecao',
      JSON.stringify(postData)
    );
  },
  excluirRegraExcecao(regraExcecaoId) {
    return APIService.apiCall().delete(
      `validacoes-bdgds/regras-excecoes/excluir-regra-excecao/${regraExcecaoId}`
    );
  }
};
