<template>
  <div>
    <base-material-card
      color="primary"
      icon="mdi-database-eye-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Status da BDGD
          <v-progress-circular
            v-if="loading"
            size="20"
            indeterminate
            color="rgba(0, 0, 0, 0.87)"
          />
          <span v-else>
            {{ bdgd.ordinaria ? 'Ordinária' : '' }}
            {{ bdgd.data_registro | formatToMonth }} v{{ bdgd.versao }}
          </span>
        </div>
      </template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            style="float: right !important"
            class="mt-n9"
            v-bind="attrs"
            v-on="on"
          >
            <v-btn
              icon
              color="black"
              :loading="loadingHistoricoValidacoes"
              @click="baixarHistoricoValidacoes()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="22"> mdi-download-circle-outline </v-icon>
            </v-btn>
          </div>
        </template>
        Baixar o histórico de validações
      </v-tooltip>
      <v-row>
        <v-col cols="12">
          <v-progress-circular
            v-if="loading"
            size="20"
            indeterminate
            color="rgba(0, 0, 0, 0.87)"
          />
          <span v-else>
            <v-alert
              dense
              outlined
              type="info"
              color="blue"
              class="py-1 px-1"
              v-if="resumoValidacoesBdgd.length === 0"
            >
              A versão da BDGD selecionada parece não ter sido validada ainda.
            </v-alert>
            <div v-else>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-space-between bg-surface-variant"
                >
                  <v-switch
                    v-model="somentePreProtocolo"
                    hide-details
                    inset
                    label="Exibir somente testes pré-protocolo"
                  />
                  <div>
                    <router-link
                      class="mr-4"
                      :to="`/geracao-bdgd/validacoes-bdgd/painel-iq-bdgd/${bdgd.id}`"
                    >
                      Painel IQ BDGD
                    </router-link>
                    <router-link
                      to="/geracao-bdgd/validacoes-bdgd/painel-unificado"
                    >
                      Painel Unificado de Validações
                    </router-link>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-card
                    max-width="400"
                    tile
                  >
                    <v-expansion-panels
                      focusable
                      v-model="panel"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ 'Testes executados' | toUpperCase }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          class="content-expansion-p-rel-customizado"
                        >
                          <v-list>
                            <v-list-item-group
                              v-model="indiceTesteSelecionado"
                              color="primary"
                            >
                              <v-list-item
                                v-for="(teste, index) in resumoValidacoesBdgd"
                                :key="index"
                              >
                                <v-list-item-icon>
                                  <v-icon :style="getStyleByStatus(teste)">
                                    {{ getIconByStatus(teste) }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    :style="getStyleByStatus(teste)"
                                    :title="teste.nome_teste"
                                  >
                                    Etapa
                                    {{ teste.etapa_nval | addLeadingZero }} -
                                    {{ teste.sigla_teste }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ 'Registros por entidade' | toUpperCase }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          class="content-expansion-p-rel-customizado"
                        >
                          <registros-bdgd-por-entidade-data-table
                            :bdgdVersao="bdgd"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ 'Registros por TUC' | toUpperCase }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          class="content-expansion-p-rel-customizado"
                        >
                          <registros-bdgd-por-tuc-data-table
                            :bdgdVersao="bdgd"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card>
                </v-col>
                <v-col cols="9">
                  <resumo-teste
                    :bdgd="bdgd"
                    :testeSelecionado="testeSelecionado"
                    @view-errors:open-dialog="openDialogViewErrors"
                  />
                </v-col>
              </v-row>
            </div>
          </span>
        </v-col>
      </v-row>
    </base-material-card>
    <dialog-view-errors
      :bdgd="bdgd"
      :testeSelecionado="testeSelecionado"
      :dialog.sync="dialogViewErrors"
      :item="dialogViewErrorsData"
      :usuarioAdmin="userIsAdmin"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import AuthService from '@/services/AuthService';
import BdgdVersionsService from '@/services/BdgdVersionsService';
import ValidacoesBdgdsService from '@/services/ValidacoesBdgdsService';
import ResumoTeste from '@/components/geracao-bdgd/validacoes-bdgd/ResumoTeste';
import DialogViewErrors from '@/components/geracao-bdgd/validacoes-bdgd/DialogViewErrors';
import RegistrosBdgdPorEntidadeDataTable from '@/components/general/data-tables/RegistrosBdgdPorEntidadeDataTable';
import RegistrosBdgdPorTucDataTable from '@/components/general/data-tables/RegistrosBdgdPorTucDataTable';

export default {
  name: 'StatusGeralBdgd',
  components: {
    ResumoTeste,
    DialogViewErrors,
    RegistrosBdgdPorEntidadeDataTable,
    RegistrosBdgdPorTucDataTable
  },
  props: {
    bdgdVersaoId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    panel: 0,
    somentePreProtocolo: false,
    bdgd: {
      id: 0,
      data_registro: null,
      versao: 1,
      ordinaria: false
    },
    resumoValidacoesBdgd: [],
    indiceTesteSelecionado: 0,
    loading: true,
    loadingHistoricoValidacoes: false,
    dialogViewErrors: false,
    dialogViewErrorsData: {},
    userIsAdmin: false
  }),
  mounted() {
    this.getUserIsAdmin();
    this.getBdgd();
  },
  computed: {
    testeSelecionado() {
      return this.resumoValidacoesBdgd[this.indiceTesteSelecionado] ?? {};
    }
  },
  methods: {
    getUserIsAdmin() {
      AuthService.userIsAdmin().then(
        (userIsAdmin) => (this.userIsAdmin = userIsAdmin)
      );
    },
    getBdgd() {
      if (!this.bdgdVersaoId) return false;
      BdgdVersionsService.getById(this.bdgdVersaoId)
        .then((response) => (this.bdgd = response))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar os dados da versão da BDGD selecionada.',
            '',
            { position: 'topRight' }
          );
        });
    },
    getResumoValidacoesBdgd() {
      if (!(this.bdgd && this.bdgd.id)) return false;
      ValidacoesBdgdsService.getResumoValidacoesBdgd(
        this.bdgdVersaoId,
        this.somentePreProtocolo
      )
        .then(({ data }) => {
          this.indiceTesteSelecionado = 0;
          this.resumoValidacoesBdgd = data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar o resumo das validações realizadas na BDGD selecionada.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    getStyleByStatus({
      qtd_erros_criticos,
      qtd_erros_nao_criticos,
      qtd_excecoes,
      qtd_analises_futuras
    }) {
      let color = 'green';
      if (qtd_erros_criticos) {
        color = '#e91e63';
      } else if (qtd_erros_nao_criticos) {
        color = '#f9a825';
      } else if (qtd_excecoes) {
        color = 'green';
      } else if (qtd_analises_futuras) {
        color = '#ff5722';
      }
      return { color };
    },
    getIconByStatus({
      qtd_erros_criticos,
      qtd_erros_nao_criticos,
      qtd_excecoes,
      qtd_analises_futuras
    }) {
      let icon = 'mdi-check-bold';
      if (qtd_erros_criticos) {
        icon = 'mdi-alert-octagon';
      } else if (
        qtd_erros_nao_criticos ||
        qtd_excecoes ||
        qtd_analises_futuras
      ) {
        icon = 'mdi-alert-rhombus';
      }
      return icon;
    },
    baixarHistoricoValidacoes() {
      if (!(this.bdgd && this.bdgd.id)) return false;
      this.loadingHistoricoValidacoes = true;
      ValidacoesBdgdsService.baixarHistoricoValidacoes(
        this.bdgd.id,
        this.somentePreProtocolo
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const arquivo = `historico-validacoes-bdgd-${this.bdgd.id}-${timestamp}.xlsx`;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error(
            'Falha no download do histórico de validações de BDGD.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loadingHistoricoValidacoes = false));
    },
    openDialogViewErrors(data) {
      this.dialogViewErrors = true;
      this.dialogViewErrorsData = data;
    }
  },
  watch: {
    bdgdVersaoId() {
      this.getBdgd();
    },
    bdgd() {
      this.getResumoValidacoesBdgd();
    },
    somentePreProtocolo() {
      this.getResumoValidacoesBdgd();
    }
  }
};
</script>
