<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogLocal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogLocal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Status da BDGD {{ bdgd.ordinaria ? 'Ordinária' : '' }}
            {{ bdgd.data_registro | formatToMonth }} v{{ bdgd.versao }} >
            {{ toolbarTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <v-list three-line>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col
                  cols="12"
                  v-if="!loading"
                >
                  <general-progress-bars
                    class="mb-8"
                    :items="downloadProgressData"
                    prefixText="Baixando Validação"
                  />
                  <v-alert
                    dense
                    outlined
                    type="info"
                    color="blue"
                  >
                    <span>
                      Última validação #{{ item.validacao_bdgd_id }} executada
                      em {{ item.data_validacao | parseToDateTimeBR }} por
                      {{ item.usuario_validacao }}.
                    </span>
                    <span v-if="item.qtd_erros_encontrados > limit">
                      Essa tabela exibe no máximo
                      <b>{{ limit | parseNumberToIntegerBR }}</b> registros.
                    </span>
                    <span>
                      <a
                        href="javascript:void(0)"
                        @click="baixarErrosValidacao()"
                        >Clique aqui</a
                      >
                      para fazer o download da planilha completa.
                    </span>
                    <span v-if="testeSelecionado.sigla_teste === 'ESP'">
                      Ou
                      <a
                        href="javascript:void(0)"
                        @click="baixarErrosValidacaoFormatoShapefile()"
                        >clique aqui</a
                      >
                      para fazer o download no formato shapefile.
                    </span>
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="[
                      { text: 'Nome da validação', value: 'nome_validacao' },
                      { text: 'Entidade validada', value: 'entidade' },
                      { text: 'Campos validados', value: 'campo' },
                      { text: 'Ocorrências', value: 'qtd_erros_encontrados' },
                      { text: 'Status dos campos', value: 'status_campo' }
                    ]"
                    :items="[item]"
                    :items-per-page="-1"
                    :mobile-breakpoint="0"
                    hide-default-footer
                    disable-sort
                  >
                    <template v-slot:[`item.nome_validacao`]="{ item }">
                      {{ item.tipo_ocorrencia }} :: {{ item.nome_validacao }}
                    </template>
                    <template v-slot:[`item.qtd_erros_encontrados`]="{ item }">
                      {{ item.qtd_erros_encontrados | parseNumberToIntegerBR }}
                      em
                      {{ item.qtd_registros_entidade | parseNumberToIntegerBR }}
                      <span v-if="item.qtd_registros_entidade > 1">
                        linhas
                      </span>
                      <span v-else> linha </span>
                      <span v-if="item.perc_erros * 100">
                        ({{ (item.perc_erros * 100) | parseNumberToFloatBR }}%)
                      </span>
                    </template>
                    <template v-slot:[`item.status_campo`]="{ item }">
                      <general-status :status="item.status_campo" />
                    </template>
                  </v-data-table>
                </v-col>
                <v-col
                  cols="12"
                  v-if="!loading && !item.pre_protocolo"
                >
                  <form-regra-excecao
                    v-if="usuarioAdmin"
                    :testeSelecionado="testeSelecionado"
                    :item="item"
                  />
                </v-col>
                <v-col
                  cols="12"
                  v-if="!loading && itemNoMapa"
                >
                  <visualizar-item-mapa
                    :entidadeBdgd="itemNoMapa.entidadeValidacao"
                    :bdgdVersao="itemNoMapa.bdgd"
                    :linhaCodId="itemNoMapa.itemCodId"
                    :linhaLatitude="itemNoMapa.itemLatitude"
                    :linhaLongitude="itemNoMapa.itemLongitude"
                  />
                </v-col>
                <v-col
                  cols="12"
                  v-if="item.qtd_erros_encontrados > 0"
                >
                  <v-progress-circular
                    v-if="loading"
                    size="20"
                    indeterminate
                    color="rgba(0, 0, 0, 0.87)"
                  />
                  <div v-else>
                    <v-text-field
                      v-if="errosValidacao.length"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Procurar"
                      hide-details
                      single-line
                      dense
                      class="mx-2 mb-10"
                      @click.stop=""
                    />
                    <v-data-table
                      dense
                      :headers="cabecalhoErrosValidacao"
                      :items="errosValidacao"
                      :search.sync="search"
                      :items-per-page="5"
                      class="tabela-logs"
                    >
                      <template v-slot:[`item.cod_id`]="{ item }">
                        <span v-if="testeSelecionado.sigla_teste === 'ESP'">
                          <a
                            class="link"
                            title="Clique aqui para visualizar o item no mapa"
                            @click="
                              () =>
                                exibeItemNoMapa(entidadeValidacao, bdgd, item)
                            "
                          >
                            {{ item.cod_id }}
                          </a>
                        </span>
                        <span v-else>
                          {{ item.cod_id }}
                        </span>
                      </template>
                      <template v-slot:[`item.longitude`]="{ item }">
                        <span v-if="testeSelecionado.sigla_teste === 'ESP'">
                          <a
                            class="link"
                            title="Clique aqui para visualizar o item no mapa"
                            @click="
                              () =>
                                exibeItemNoMapa(entidadeValidacao, bdgd, item)
                            "
                          >
                            {{ item.longitude }}
                          </a>
                        </span>
                        <span v-else>
                          {{ item.longitude }}
                        </span>
                      </template>
                      <template v-slot:[`item.latitude`]="{ item }">
                        <span v-if="testeSelecionado.sigla_teste === 'ESP'">
                          <a
                            class="link"
                            title="Clique aqui para visualizar o item no mapa"
                            @click="
                              () =>
                                exibeItemNoMapa(entidadeValidacao, bdgd, item)
                            "
                          >
                            {{ item.latitude }}
                          </a>
                        </span>
                        <span v-else>
                          {{ item.latitude }}
                        </span>
                      </template>
                      <template v-slot:[`item.longitude1`]="{ item }">
                        <span v-if="testeSelecionado.sigla_teste === 'ESP'">
                          <a
                            class="link"
                            title="Clique aqui para visualizar o item no mapa"
                            @click="
                              () =>
                                exibeItemNoMapa(entidadeValidacao, bdgd, item)
                            "
                          >
                            {{ item.longitude1 }}
                          </a>
                        </span>
                        <span v-else>
                          {{ item.longitude1 }}
                        </span>
                      </template>
                      <template v-slot:[`item.latitude1`]="{ item }">
                        <span v-if="testeSelecionado.sigla_teste === 'ESP'">
                          <a
                            class="link"
                            title="Clique aqui para visualizar o item no mapa"
                            @click="
                              () =>
                                exibeItemNoMapa(entidadeValidacao, bdgd, item)
                            "
                          >
                            {{ item.latitude1 }}
                          </a>
                        </span>
                        <span v-else>
                          {{ item.latitude1 }}
                        </span>
                      </template>
                      <template v-slot:[`item.longitude2`]="{ item }">
                        <span v-if="testeSelecionado.sigla_teste === 'ESP'">
                          <a
                            class="link"
                            title="Clique aqui para visualizar o item no mapa"
                            @click="
                              () =>
                                exibeItemNoMapa(entidadeValidacao, bdgd, item)
                            "
                          >
                            {{ item.longitude2 }}
                          </a>
                        </span>
                        <span v-else>
                          {{ item.longitude2 }}
                        </span>
                      </template>
                      <template v-slot:[`item.latitude2`]="{ item }">
                        <span v-if="testeSelecionado.sigla_teste === 'ESP'">
                          <a
                            class="link"
                            title="Clique aqui para visualizar o item no mapa"
                            @click="
                              () =>
                                exibeItemNoMapa(entidadeValidacao, bdgd, item)
                            "
                          >
                            {{ item.latitude2 }}
                          </a>
                        </span>
                        <span v-else>
                          {{ item.latitude2 }}
                        </span>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import ValidacoesBdgdsService from '@/services/ValidacoesBdgdsService';
import GeneralStatus from '@/components/general/GeneralStatus.vue';
import GeneralProgressBars from '@/components/general/GeneralProgressBars.vue';
import FormRegraExcecao from './FormRegraExcecao.vue';
import VisualizarItemMapa from './TesteRelacoesEspaciaisVisualizarItemMapa.vue';

export default {
  name: 'DialogViewErrors',
  components: {
    GeneralStatus,
    GeneralProgressBars,
    FormRegraExcecao,
    VisualizarItemMapa
  },
  props: {
    bdgd: {
      type: Object,
      required: true
    },
    testeSelecionado: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    usuarioAdmin: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    errosValidacao: [],
    naoExibirColunas: ['GEOM', 'TIPO', 'NOME_VALIDACAO'],
    search: '',
    loading: false,
    downloadProgressData: {},
    limit: 100,
    itemNoMapa: null
  }),
  computed: {
    dialogLocal: {
      get() {
        return this.dialog;
      },
      set(newValue) {
        this.$emit('update:dialog', newValue);
      }
    },
    entidadeValidacao() {
      return this.item.entidade.toLowerCase();
    },
    toolbarTitle() {
      const etapaNval = this.testeSelecionado?.etapa_nval;
      const siglaTeste = this.testeSelecionado?.sigla_teste;
      return `Etapa ${etapaNval} - ${siglaTeste}`;
    },
    cabecalhoErrosValidacao() {
      if (!this.errosValidacao.length) return [];
      let cabecalho = Object.keys(this.errosValidacao[0]).map((item) => ({
        text: item.toUpperCase(),
        value: item
      }));
      return cabecalho.filter(
        (campo) => !this.naoExibirColunas.includes(campo.text)
      );
    }
  },
  methods: {
    getErrosValidacao() {
      if (!(this.testeSelecionado && this.item)) return false;
      this.loading = true;
      this.errosValidacao = [];
      ValidacoesBdgdsService.getErrosValidacao(
        this.testeSelecionado.sigla_teste,
        this.item.validacao_bdgd_id,
        this.item,
        this.limit
      )
        .then(({ data }) => (this.errosValidacao = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar os erros de validação do teste selecionado.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    getArquivoDownload(extensao = 'xlsx') {
      let { data_registro, versao } = this.bdgd;
      let { entidade, nome_validacao, validacao_bdgd_id } = this.item;
      let sigla_teste = this.testeSelecionado.sigla_teste;
      const mes = dayjs(data_registro).format('YYYY-MM');
      let nomeArquivo = `validacao-bdgd-mes-${mes}-versao-${versao}-${sigla_teste}-${entidade}-${nome_validacao}-${validacao_bdgd_id}.${extensao}`;
      return nomeArquivo.toLowerCase().replaceAll('_', '-');
    },
    baixarErrosValidacao() {
      const id = this.item.validacao_bdgd_id;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      ValidacoesBdgdsService.baixarErrosValidacao(
        this.testeSelecionado.sigla_teste,
        this.item.validacao_bdgd_id,
        this.item,
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.getArquivoDownload('xlsx'));
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo xlsx com os logs de validação.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    },
    baixarErrosValidacaoFormatoShapefile() {
      const id = this.item.validacao_bdgd_id;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      ValidacoesBdgdsService.baixarErrosValidacaoFormatoShapefile(
        this.testeSelecionado.sigla_teste,
        this.item.validacao_bdgd_id,
        this.item,
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.getArquivoDownload('zip'));
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo shapefile com as linhas reprovadas no teste.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    },
    exibeItemNoMapa(entidadeValidacao, bdgd, item) {
      const { longitude: itemLongitude, latitude: itemLatitude } =
        this.getCoordenadasItem(item);
      this.itemNoMapa = {
        entidadeValidacao,
        bdgd,
        itemCodId: item.cod_id,
        itemLongitude,
        itemLatitude
      };
    },
    getCoordenadasItem(item) {
      let {
        longitude,
        latitude,
        longitude1,
        latitude1,
        longitude2,
        latitude2
      } = item;
      if (!longitude && longitude1) longitude = longitude1;
      if (!longitude && longitude2) longitude = longitude2;
      if (!latitude && latitude1) latitude = latitude1;
      if (!latitude && latitude2) latitude = latitude2;
      return {
        longitude,
        latitude
      };
    }
  },
  watch: {
    item() {
      this.itemNoMapa = null;
      this.getErrosValidacao();
    },
    dialog() {
      if (this.dialog === false) this.itemNoMapa = null;
    }
  }
};
</script>

<style scoped>
.tabela-logs {
  max-width: 98vw;
  overflow: auto;
}

.link {
  text-decoration: underline;
}
</style>
