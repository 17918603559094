<template>
  <v-container fluid>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      class="mb-5"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Linhas'
      }"
      :options="{
        itemsPerPage
      }"
      no-data-text="Nenhum registro importado"
    >
      <template v-slot:[`item.entidade`]="{ item }">
        {{ item.entidade | toUpperCase }}
      </template>
      <template v-slot:[`item.registros`]="{ item }">
        <a
          class="link"
          title="Clique aqui para visualizar os registros importados na entidade"
          @click="
            $router.push(
              `/geracao-bdgd/entidade-bdgd/visualizar/${item.entidade.toLowerCase()}/${
                bdgdVersao.id
              }`
            )
          "
          >{{ item.registros | parseNumberToIntegerBR }}</a
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ValidacoesBdgdsService from '@/services/ValidacoesBdgdsService';

export default {
  name: 'RegistrosBdgdPorEntidadeDataTable',
  props: {
    bdgdVersao: {
      type: Object,
      required: true,
      default: () => {}
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  data: () => ({
    search: '',
    items: [],
    loading: false,
    headers: [
      {
        text: 'Entidade',
        value: 'entidade'
      },
      {
        text: 'Registros',
        value: 'registros'
      }
    ]
  }),
  created() {
    this.getRegistrosImportados();
  },
  methods: {
    getRegistrosImportados() {
      this.loading = true;
      const mes = dayjs(this.bdgdVersao.data_registro).format('YYYY-MM-DD');
      ValidacoesBdgdsService.getImportedEntitiesAndRegistersByDate(
        mes,
        this.$store.getters.getCompanyId,
        this.bdgdVersao.id
      )
        .then((response) => (this.items = response.data))
        .catch(() =>
          this.$toast.error(
            'Erro ao recuperar os registros importados da BDGD.',
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    bdgdVersao() {
      this.getRegistrosImportados();
    }
  }
};
</script>

<style scoped>
.link {
  text-decoration: underline;
}
</style>
