<template>
  <div>
    <v-card tile>
      <v-subheader>
        {{ tituloResumo | toUpperCase }}
      </v-subheader>
      <v-divider />
      <v-card-text>
        <general-progress-bars
          class="mb-5"
          :items="downloadProgressData"
          prefixText="Baixando Validação"
        />
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr
                v-if="testeSelecionado.qtd_erros_criticos"
                :style="{ color: '#e91e63' }"
              >
                <td style="width: 30%">Total de erros críticos encontrados:</td>
                <td>
                  {{
                    testeSelecionado.qtd_erros_criticos | parseNumberToIntegerBR
                  }}
                </td>
              </tr>
              <tr
                v-if="testeSelecionado.qtd_erros_nao_criticos"
                :style="{ color: '#f9a825' }"
              >
                <td style="width: 30%">
                  Total de erros não críticos encontrados:
                </td>
                <td>
                  {{
                    testeSelecionado.qtd_erros_nao_criticos
                      | parseNumberToIntegerBR
                  }}
                </td>
              </tr>
              <tr
                v-if="testeSelecionado.qtd_excecoes"
                :style="{ color: 'green' }"
              >
                <td style="width: 30%">Total de exceções encontradas:</td>
                <td>
                  {{ testeSelecionado.qtd_excecoes | parseNumberToIntegerBR }}
                </td>
              </tr>
              <tr
                v-if="testeSelecionado.qtd_analises_futuras"
                :style="{ color: '#ff5722' }"
              >
                <td style="width: 30%">
                  Total de análises futuras encontradas:
                </td>
                <td>
                  {{
                    testeSelecionado.qtd_analises_futuras
                      | parseNumberToIntegerBR
                  }}
                </td>
              </tr>
              <tr :style="{ color: 'green' }">
                <td style="width: 30%">Total linhas importadas na BDGD:</td>
                <td>
                  {{ bdgd.registros | parseNumberToIntegerBR }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider class="mb-5" />
        <v-expansion-panels
          focusable
          v-model="panel"
          @change="search = ''"
        >
          <v-text-field
            v-if="entidadesTestadas.length >= 10"
            v-model="searchEntities"
            append-icon="mdi-magnify"
            label="Procurar entidades"
            hide-details
            single-line
            dense
            class="mt-0 mb-5"
            @click.stop=""
          />
          <v-progress-linear
            indeterminate
            v-show="loading"
          />
          <v-expansion-panel
            v-for="(entidade, key) in entidadesFiltradas"
            :key="key"
            :disabled="loading"
          >
            <v-expansion-panel-header disable-icon-rotate>
              {{ entidade }}
              <v-text-field
                v-if="
                  key === panel && resultadoPorEntidade[entidade].length > 10
                "
                v-model="search"
                append-icon="mdi-magnify"
                label="Procurar"
                hide-details
                single-line
                dense
                class="mx-10 mt-0"
                @click.stop=""
              />
              <template v-slot:actions>
                <v-icon
                  :color="getCorStatusEntidade(resultadoPorEntidade[entidade])"
                >
                  {{ getIconeStatusEntidade(resultadoPorEntidade[entidade]) }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="content-expansion-p-rel-customizado"
            >
              <v-data-table
                class="table-rel-customizado"
                :headers="[
                  { text: 'Nome da validação', value: 'nome_validacao' },
                  { text: 'Campos validados', value: 'campo' },
                  { text: 'Ocorrências', value: 'qtd_erros_encontrados' },
                  { text: 'Status dos campos', value: 'status_campo' },
                  {
                    text: 'Logs',
                    value: 'logs',
                    class: 'pl-4',
                    sortable: false
                  }
                ]"
                :items="resultadoPorEntidade[entidade]"
                :items-per-page="-1"
                :mobile-breakpoint="0"
                :search.sync="search"
                hide-default-footer
                fixed-header
                height="300px"
              >
                <template v-slot:[`item.nome_validacao`]="{ item }">
                  {{ item.tipo_ocorrencia }} :: {{ item.nome_validacao }}
                </template>
                <template v-slot:[`item.qtd_erros_encontrados`]="{ item }">
                  <a
                    class="link"
                    title="Clique aqui para visualizar os erros encontrados na última validação"
                    @click="() => emitEventViewErrors(item)"
                  >
                    {{ item.qtd_erros_encontrados | parseNumberToIntegerBR }}</a
                  >
                  em
                  {{ item.qtd_registros_entidade | parseNumberToIntegerBR }}
                  <span v-if="item.qtd_registros_entidade > 1"> linhas </span>
                  <span v-else> linha </span>
                  <span v-if="item.perc_erros * 100">
                    ({{ (item.perc_erros * 100) | parseNumberToFloatBR }}%)
                  </span>
                </template>
                <template v-slot:[`item.status_campo`]="{ item }">
                  <general-status :status="item.status_campo" />
                </template>
                <template v-slot:[`item.logs`]="{ item }">
                  <v-btn
                    class="px-1"
                    min-width="0"
                    fab
                    icon
                    x-small
                    title="Baixar linhas para análise"
                    @click="baixarErrosValidacao(item)"
                    :loading="
                      downloadProgressData[item.id] !== undefined ? true : false
                    "
                    v-if="item.qtd_erros_encontrados"
                  >
                    <v-icon small> mdi-download </v-icon>
                  </v-btn>
                  <v-btn
                    class="px-1"
                    min-width="0"
                    fab
                    icon
                    x-small
                    title="Baixar SQL executado na validação"
                    @click="baixarSqlErrosValidacao(item)"
                  >
                    <v-icon small> mdi-file-code-outline </v-icon>
                  </v-btn>
                  <v-btn
                    min-width="0"
                    class="px-1"
                    fab
                    icon
                    x-small
                    @click="
                      (dialogCreationLog = true),
                        (dialogCreationLogData = {
                          username: item.usuario_validacao,
                          created: item.data_validacao
                        })
                    "
                  >
                    <v-icon medium> mdi-table-search </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ValidacoesBdgdsService from '@/services/ValidacoesBdgdsService';

export default {
  name: 'ResumoTeste',
  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue')
  },
  props: {
    bdgd: {
      type: Object,
      required: true
    },
    testeSelecionado: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    resultadoUltimaExecucaoTeste: [],
    dialogCreationLog: false,
    dialogCreationLogData: {
      username: null,
      created: null
    },
    panel: null,
    search: '',
    searchEntities: '',
    loading: true,
    downloadProgressData: {}
  }),
  mounted() {
    this.getResultadoUltimaExecucaoTeste();
  },
  computed: {
    tituloResumo() {
      return `Resumo do teste :: ${this.testeSelecionado?.nome_teste}`;
    },
    entidadesTestadas() {
      const entidades = this.resultadoUltimaExecucaoTeste.map((resultado) =>
        resultado.entidade.toUpperCase()
      );
      return [...new Set(entidades)];
    },
    entidadesFiltradas() {
      if (this.searchEntities) {
        return this.entidadesTestadas.filter((entidade) =>
          entidade.includes(this.searchEntities.toUpperCase())
        );
      }
      return this.entidadesTestadas;
    },
    resultadoPorEntidade() {
      let resultadoPorEntidade = {};
      this.resultadoUltimaExecucaoTeste.forEach((resultado) => {
        let entidade = resultado.entidade.toUpperCase();
        if (!(entidade in resultadoPorEntidade))
          resultadoPorEntidade[entidade] = [];
        resultadoPorEntidade[entidade].push(resultado);
      });
      return resultadoPorEntidade;
    }
  },
  watch: {
    testeSelecionado() {
      this.panel = null;
      this.search = '';
      this.searchEntities = '';
      this.getResultadoUltimaExecucaoTeste();
    }
  },
  methods: {
    getResultadoUltimaExecucaoTeste() {
      if (
        !(
          this.bdgd &&
          this.bdgd.id &&
          this.testeSelecionado &&
          this.testeSelecionado.sigla_teste
        )
      )
        return false;
      this.loading = true;
      ValidacoesBdgdsService.getResultadoUltimaExecucaoTeste(
        this.bdgd.id,
        this.testeSelecionado.sigla_teste
      )
        .then(({ data }) => (this.resultadoUltimaExecucaoTeste = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            `Erro ao buscar os dados da última execução do teste ${this.testeSelecionado.sigla_teste}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    baixarErrosValidacao(item) {
      const id = item.validacao_bdgd_id;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      ValidacoesBdgdsService.baixarErrosValidacao(
        this.testeSelecionado.sigla_teste,
        item.validacao_bdgd_id,
        item,
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.arquivoDownload(item));
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo xlsx com os logs de validação.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    },
    baixarSqlErrosValidacao(item) {
      const id = item.validacao_bdgd_id;
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      ValidacoesBdgdsService.baixarSqlErrosValidacao(
        this.testeSelecionado.sigla_teste,
        item.validacao_bdgd_id,
        item
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.arquivoDownload(item, 'sql'));
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo sql com as querys de validação.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    },
    arquivoDownload(item, extensao = 'xlsx') {
      let { data_registro, versao } = this.bdgd;
      let { entidade, nome_validacao, validacao_bdgd_id } = item;
      let sigla_teste = this.testeSelecionado.sigla_teste;
      const mes = dayjs(data_registro).format('YYYY-MM');
      let nomeArquivo = `validacao-bdgd-mes-${mes}-versao-${versao}-${sigla_teste}-`;
      nomeArquivo += `${entidade}-${nome_validacao}-${validacao_bdgd_id}.${extensao}`;
      return nomeArquivo.toLowerCase().replaceAll('_', '-');
    },
    getStatusCamposEntidade(resultadoPorEntidade) {
      let statusCampos = resultadoPorEntidade.map(({ status_campo }) =>
        status_campo.toUpperCase()
      );
      statusCampos = [...new Set(statusCampos)];
      return statusCampos;
    },
    getCorStatusEntidade(resultadoPorEntidade) {
      const statusCampos = this.getStatusCamposEntidade(resultadoPorEntidade);
      let cor = 'green';
      if (statusCampos.includes('ERRO_CRITICO')) {
        cor = '#e91e63';
      } else if (statusCampos.includes('ERRO_NAO_CRITICO')) {
        cor = '#f9a825';
      } else if (statusCampos.includes('EXCECAO')) {
        cor = 'green';
      } else if (statusCampos.includes('ANALISE_FUTURA')) {
        cor = '#ff5722';
      }
      return cor;
    },
    getIconeStatusEntidade(resultadoPorEntidade) {
      const statusCampos = this.getStatusCamposEntidade(resultadoPorEntidade);
      let icon = 'mdi-check-bold';
      if (statusCampos.includes('ERRO_CRITICO')) {
        icon = 'mdi-alert-octagon';
      } else if (
        statusCampos.includes('ERRO_NAO_CRITICO') ||
        statusCampos.includes('EXCECAO') ||
        statusCampos.includes('ANALISE_FUTURA')
      ) {
        icon = 'mdi-alert-rhombus';
      }
      return icon;
    },
    emitEventViewErrors(data) {
      this.$emit('view-errors:open-dialog', data);
    }
  }
};
</script>

<style>
.content-expansion-p-rel-customizado .v-expansion-panel-content__wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.table-rel-customizado table tr td {
  font-size: 13px !important;
}
.table-rel-customizado table tr th {
  font-size: 13px !important;
  font-weight: 400;
}

.link {
  text-decoration: underline;
}
</style>
