<template>
  <div id="visualizar-item-mapa"></div>
</template>

<script>
import L from 'leaflet';
import wms from 'leaflet.wms';
import dayjs from 'dayjs';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import GeoServerService from '@/services/GeoServerService';
import BasesDadosService from '@/services/BasesDadosService';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);
const geoserverBaseUrl = GeoServerService.getGeoServerBaseUrl();
const baseUrl = GeoServerService.getWmsBaseUrl();
const workspace = GeoServerService.getWorkspaceDefault();
const ignoredProperties = [
  'GEOM',
  'CONTADOR',
  'COMPANY',
  'COMPANY_ID',
  'MES_DADOS',
  'BDGD_VERSAO',
  'BDGD_VERSAO_ID'
];

export default {
  name: 'TesteRelacoesEspaciaisVisualizarItemMapa',
  mixins: [geocoderMixins, browserPrintMixins, scaleMixins, filtersMixins],
  props: {
    entidadeBdgd: {
      type: String,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    },
    linhaCodId: {
      type: String,
      required: true
    },
    linhaLongitude: {
      type: String,
      required: true
    },
    linhaLatitude: {
      type: String,
      required: true
    }
  },
  data: () => ({
    bases: [],
    map: null,
    mapaId: 'visualizar-item-mapa',
    layerControl: null,
    layers: {},
    zoom: 15
  }),
  mounted() {
    this.configuraMapa(4);
    // Coloca o centro do mapa próximo a capital onde está a distribuidora
    let [longitude, latitude] =
      this.$store.getters.getCompanyCapitalCoordinates;
    this.setView(latitude, longitude);
    this.map.setZoom(this.zoom);
    this.getBasesDados(this.mesDados, this.versaoBdgd);
    if (this.linhaCodId) this.plotaItemNoMapa(this.linhaCodId);
  },
  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },
    mesDados() {
      return this.bdgdVersao && this.bdgdVersao.data_registro
        ? dayjs(this.bdgdVersao.data_registro).format('YYYY-MM-01')
        : null;
    },
    versaoBdgd() {
      return this.bdgdVersao && this.bdgdVersao.versao
        ? this.bdgdVersao.versao
        : 1;
    },
    cqlFilter() {
      return this.companyId && this.mesDados && this.versaoBdgd
        ? this.getBasicCqlFilter(this.companyId, this.mesDados, this.versaoBdgd)
        : this.getBasicCqlFilter(this.companyId);
    },
    ogcFilter() {
      return this.companyId && this.mesDados && this.versaoBdgd
        ? this.getBasicOgcFilter(this.companyId, this.mesDados, this.versaoBdgd)
        : this.getBasicOgcFilter(this.companyId);
    },
    viewEntidade() {
      return `view_${this.entidadeBdgd.toLowerCase()}`;
    }
  },
  watch: {
    linhaCodId() {
      if (this.linhaCodId) this.plotaItemNoMapa(this.linhaCodId);
    }
  },
  methods: {
    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });
      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);
      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
      this.createBrowserPrintControl(L, 'Visualizar Mapa').addTo(this.map);
    },
    setView(latitude = -2.5606242, longitude = -44.4016494) {
      this.map.setView([latitude, longitude]);
    },
    getBasesDados(dataRegistro, versao) {
      BasesDadosService.getBasesDadosGeograficasLiberadas(
        this.companyId,
        dataRegistro,
        versao
      )
        .then((response) => {
          this.bases = response.data;
          this.limparBasesLayers();
          this.addBasesLayers();
          if (!this.bases.length) {
            this.$toast.warning(
              'Não existe nenhuma entidade geografica disponível para esse mês e versão da BDGD escolhida.',
              '',
              { position: 'topRight' }
            );
          }
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar bases de dados para layers.',
            '',
            { position: 'topRight' }
          );
        });
    },
    limparBasesLayers() {
      Object.keys(this.layers).forEach((nomeTabela) => {
        this.layerControl.removeLayer(this.layers[nomeTabela]);
      });
    },
    addBasesLayers() {
      this.bases.forEach((base) => this.addBaseLayer(base));
    },
    addBaseLayer(base) {
      // Se o layer já existe, remove-o
      if (this.layers[base.nome_tabela]) {
        this.layerControl.removeLayer(this.layers[base.nome_tabela]);
      }

      const MySource = wms.Source.extend({
        showFeatureInfo: this.showFeatureInfo
      });

      let config = {
        format: 'image/png',
        transparent: true,
        identify: true // Funcionalidade de clicar na feição e obter os dados
      };

      // Verifica qual tipo de filtro será utilizado, CQL ou OGC e configura o parâmetro correto
      this.isCqlFilter()
        ? (config.cql_filter = this.cqlFilter)
        : (config.filter = this.ogcFilter);

      // Cria o layer
      let source = new MySource(`${geoserverBaseUrl}/${baseUrl}`, config);
      this.layers[base.nome_tabela] = source.getLayer(
        `${workspace}:${base.nome_tabela}`
      );

      // Adiciona o layer no mapa
      this.layerControl.addOverlay(
        this.layers[base.nome_tabela],
        base.descricao
      );
    },
    plotaItemNoMapa(linhaCodId) {
      if (!linhaCodId) return;

      const MySource = wms.Source.extend({
        showFeatureInfo: this.showFeatureInfo
      });

      let config = {
        format: 'image/png',
        transparent: true,
        identify: true // Funcionalidade de clicar na feição e obter os dados
      };

      // Verifica qual tipo de filtro será utilizado, CQL ou OGC e configura o parâmetro correto
      if (this.isCqlFilter()) {
        config.cql_filter = `${this.cqlFilter} and (cod_id = '${linhaCodId}')`;
      } else {
        config.filter = `<And>${this.ogcFilter}<PropertyIsEqualTo><PropertyName>cod_id</PropertyName><Literal>${linhaCodId}</Literal></PropertyIsEqualTo></And>`;
      }

      // Cria o layer
      let source = new MySource(`${geoserverBaseUrl}/${baseUrl}`, config);
      this.layers[linhaCodId] = source.getLayer(
        `${workspace}:${this.viewEntidade}`
      );
      this.layers[linhaCodId].addTo(this.map);

      // Zoom to layer
      if (!(this.linhaLongitude && this.linhaLatitude)) return;
      this.map.flyTo(
        {
          lon: this.linhaLongitude,
          lat: this.linhaLatitude
        },
        this.zoom // zoom
      );
    },
    showFeatureInfo(latlng, info) {
      if (info.includes('no features were found')) return;
      let arrInfo = info.split('\n');
      let stringInfo = '';
      arrInfo.forEach((element, key) => {
        if ([0, 1, arrInfo.length - 1, arrInfo.length - 2].includes(key))
          return;
        if (element.slice(-4) == 'null') return;
        element = element.split('=');
        let property = element[0].trim().toUpperCase();
        if (ignoredProperties.includes(property)) return;
        element = property + ': ' + element[1].trim();
        stringInfo += `${element}<br>`;
      });
      L.popup()
        .setLatLng(latlng)
        .setContent(`<div id="div-popup">${stringInfo}</div>`)
        .openOn(this.map);
    }
  }
};
</script>

<style>
#visualizar-item-mapa {
  height: 30vh;
  z-index: 0;
}

#div-popup {
  max-height: 200px;
  overflow-y: auto;
}
</style>
