<template>
  <v-container fluid>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      class="mb-5"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Linhas'
      }"
      no-data-text="Nenhum registro importado"
    >
      <template v-slot:[`item.entidade`]="{ item }">
        {{ item.entidade | toUpperCase }}
      </template>
      <template v-slot:[`item.registros`]="{ item }">
        {{ item.registros | parseNumberToIntegerBR }}
        <span v-if="item.registros !== item.qtd_mcpse">
          ({{ item.qtd_mcpse_convertida | parseNumberToFloatBR }}
          {{ item.unidade_mcpse_convertida }})
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import BdgdVersionsService from '@/services/BdgdVersionsService';

export default {
  name: 'RegistrosBdgdPorTucDataTable',
  props: {
    bdgdVersao: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data: () => ({
    search: '',
    items: [],
    loading: false,
    headers: [
      {
        text: 'Entidade',
        value: 'entidade'
      },
      {
        text: 'TUC',
        value: 'tuc'
      },
      {
        text: 'Registros',
        value: 'registros'
      }
    ]
  }),
  created() {
    this.getResumoBdgdPorTuc();
  },
  methods: {
    getResumoBdgdPorTuc() {
      this.loading = true;
      BdgdVersionsService.getResumoBdgdPorTuc(this.bdgdVersao.id)
        .then((response) => (this.items = response.data))
        .catch(() =>
          this.$toast.error(
            'Erro ao recuperar os registros importados da BDGD agrupados por TUC.',
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    bdgdVersao() {
      this.getResumoBdgdPorTuc();
    }
  }
};
</script>
