<template>
  <v-container fluid>
    <status-geral
      v-if="bdgdVersaoId"
      :bdgdVersaoId="bdgdVersaoId"
    />
  </v-container>
</template>

<script>
import StatusGeral from '@/components/geracao-bdgd/validacoes-bdgd/StatusGeralBdgd';
export default {
  name: 'StatusGeralBdgd',
  components: {
    StatusGeral
  },
  computed: {
    bdgdVersaoId() {
      return +this.$route.params.id;
    }
  }
};
</script>
