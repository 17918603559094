<template>
  <div v-if="!item.pre_protocolo">
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <v-expansion-panels
      focusable
      v-else
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Configurar Regra de Exceção
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            class="mt-4"
            align="center"
          >
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Condição</th>
                      <th>L1</th>
                      <th>L2</th>
                      <th><= L1</th>
                      <th>> L1 e <= L2</th>
                      <th>> L2</th>
                      <th>Justificativa da Regra</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>
                      <v-textarea
                        v-model="condicao"
                        class="mx-2"
                        :label="null"
                        placeholder="Opcional"
                        rows="4"
                        no-resize
                        clearable
                      />
                    </td>
                    <td>
                      <div class="mb-2">
                        <v-text-field
                          v-model="limite1_percentual"
                          label="Limite 1 (%)"
                          type="number"
                          step="0.1"
                          min="0"
                        />
                      </div>
                      <div class="mb-2">
                        <v-text-field
                          v-model="limite1_unidade"
                          label="Limite 1 (un)"
                          type="number"
                          step="1"
                          min="0"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="mb-2">
                        <v-text-field
                          v-model="limite2_percentual"
                          label="Limite 2 (%)"
                          type="number"
                          step="0.1"
                          min="0"
                        />
                      </div>
                      <div class="mb-2">
                        <v-text-field
                          v-model="limite2_unidade"
                          label="Limite 2 (un)"
                          type="number"
                          step="1"
                          min="0"
                        />
                      </div>
                    </td>
                    <td>
                      <v-radio-group v-model="status1">
                        <v-radio
                          value="ERRO_NAO_CRITICO"
                          :color="corStatus1"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus1 }">
                              Erro Não Crítico
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="ERRO_CRITICO"
                          :color="corStatus1"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus1 }">
                              Erro Crítico
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="EXCECAO"
                          :color="corStatus1"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus1 }">
                              Exceção
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="ANALISE_FUTURA"
                          :color="corStatus1"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus1 }">
                              Análise Futura
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group v-model="status2">
                        <v-radio
                          value="ERRO_NAO_CRITICO"
                          :color="corStatus2"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus2 }">
                              Erro Não Crítico
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="ERRO_CRITICO"
                          :color="corStatus2"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus2 }">
                              Erro Crítico
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="EXCECAO"
                          :color="corStatus2"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus2 }">
                              Exceção
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="ANALISE_FUTURA"
                          :color="corStatus2"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus2 }">
                              Análise Futura
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group v-model="status3">
                        <v-radio
                          value="ERRO_NAO_CRITICO"
                          :color="corStatus3"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus3 }">
                              Erro Não Crítico
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="ERRO_CRITICO"
                          :color="corStatus3"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus3 }">
                              Erro Crítico
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="EXCECAO"
                          :color="corStatus3"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus3 }">
                              Exceção
                            </span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="ANALISE_FUTURA"
                          :color="corStatus3"
                        >
                          <template v-slot:label>
                            <span :style="{ color: corStatus3 }">
                              Análise Futura
                            </span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-textarea
                        v-model="justificativa_regra"
                        class="mx-2"
                        :label="null"
                        placeholder="Opcional"
                        rows="4"
                        no-resize
                        clearable
                      />
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" />
            <v-col cols="2">
              <v-btn
                color="green darken-1"
                class="ml-2"
                outlined
                @click="salvarRegra()"
                :disabled="!podeSalvarRegra"
              >
                Salvar
              </v-btn>
              <v-btn
                color="red darken-1"
                class="ml-2"
                outlined
                @click="excluirRegra()"
                :disabled="!podeExcluirRegra"
              >
                Excluir
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ValidacoesBdgdsRegrasExcecoesService from '@/services/ValidacoesBdgdsRegrasExcecoesService';

export default {
  name: 'FormRegraExcecao',
  props: {
    testeSelecionado: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    regra_excecao_id: null,
    condicao: null,
    limite1_percentual: null,
    limite1_unidade: null,
    limite2_percentual: null,
    limite2_unidade: null,
    status1: null,
    status2: null,
    status3: null,
    justificativa_regra: null
  }),
  mounted() {
    this.getRegraExcecao();
  },
  computed: {
    corStatus1() {
      return this.getCorPorStatus(this.status1);
    },
    corStatus2() {
      return this.getCorPorStatus(this.status2);
    },
    corStatus3() {
      return this.getCorPorStatus(this.status3);
    },
    podeSalvarRegra() {
      return (
        (this.limite1_percentual !== null || this.limite1_unidade !== null) &&
        this.status1 !== null &&
        this.status2 !== null &&
        this.status3 !== null
      );
    },
    podeExcluirRegra() {
      return this.regra_excecao_id !== null;
    }
  },
  methods: {
    getCorPorStatus(cor) {
      switch (cor) {
        case 'ERRO_CRITICO':
          return '#e91e63';
        case 'ERRO_NAO_CRITICO':
          return '#f9a825';
        case 'EXCECAO':
          return 'green';
        case 'ANALISE_FUTURA':
          return '#ff5722';
      }
      return null;
    },
    getRegraExcecao() {
      this.loading = true;
      const { entidade, campo, nome_validacao, tipo_ocorrencia } = this.item;
      const sigla_teste = this.testeSelecionado.sigla_teste;
      ValidacoesBdgdsRegrasExcecoesService.getRegraExcecao({
        entidade,
        campo,
        nome_validacao,
        tipo_ocorrencia,
        sigla_teste
      })
        .then((response) => {
          const {
            id,
            condicao,
            limite1_percentual,
            limite1_unidade,
            limite2_percentual,
            limite2_unidade,
            status1,
            status2,
            status3,
            justificativa_regra
          } = response.data;
          this.regra_excecao_id = id;
          this.condicao = condicao;
          this.limite1_percentual = limite1_percentual;
          this.limite1_unidade = limite1_unidade;
          this.limite2_percentual = limite2_percentual;
          this.limite2_unidade = limite2_unidade;
          this.status1 = status1;
          this.status2 = status2;
          this.status3 = status3;
          this.justificativa_regra = justificativa_regra;
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao tentar buscar a regra de exceção do tipo de teste ${this.item.nome_validacao}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    salvarRegra() {
      if (
        !confirm(
          'Atenção, a regra só valerá para novos testes executados. Confirma as alterações?'
        )
      ) {
        return;
      }
      const { entidade, campo, nome_validacao, tipo_ocorrencia } = this.item;
      const sigla_teste = this.testeSelecionado.sigla_teste;
      ValidacoesBdgdsRegrasExcecoesService.salvarRegraExcecao({
        entidade,
        campo,
        nome_validacao,
        tipo_ocorrencia,
        sigla_teste,
        condicao: this.condicao,
        limite1_percentual: this.limite1_percentual,
        limite1_unidade: this.limite1_unidade,
        limite2_percentual: this.limite2_percentual,
        limite2_unidade: this.limite2_unidade,
        status1: this.status1,
        status2: this.status2,
        status3: this.status3,
        justificativa_regra: this.justificativa_regra
      })
        .then(() => {
          this.$toast.success(
            `Regra de exceção do tipo de teste ${this.item.nome_validacao} atualizada com sucesso.`,
            '',
            { position: 'topRight' }
          );
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao tentar salvar a regra de exceção do tipo de teste ${this.item.nome_validacao}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    excluirRegra() {
      if (
        !confirm('Atenção, tem certeza que deseja excluir a regra de exceção?')
      ) {
        return;
      }
      ValidacoesBdgdsRegrasExcecoesService.excluirRegraExcecao(
        this.regra_excecao_id
      )
        .then(() => {
          this.$toast.success(
            `Regra de exceção do tipo de teste ${this.item.nome_validacao} excluída.`,
            '',
            { position: 'topRight' }
          );
          this.resetarRegra();
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao tentar excluir a regra de exceção do tipo de teste ${this.item.nome_validacao}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    resetarRegra() {
      this.regra_excecao_id = null;
      this.condicao = null;
      this.limite1_percentual = null;
      this.limite1_unidade = null;
      this.limite2_percentual = null;
      this.limite2_unidade = null;
      this.status1 = null;
      this.status2 = null;
      this.status3 = null;
      this.justificativa_regra = null;
    }
  },
  watch: {
    item() {
      this.getRegraExcecao();
    }
  }
};
</script>
